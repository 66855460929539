.name-holder {
    display: flex;
    align-items: center;
    gap: 7px;
    margin-bottom: 10px;

    h3 {
        font-size: 14px;
    }

    p {
        font-size: 12px;
    }

    .compte-displayer-filter {
        width: 40px;
    }

}

.checkbox-filter {
    display: flex;
    width: 100%;

}