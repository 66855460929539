.color-box {
    padding: 0px 10px;
    border-radius: 7px;
    margin-right: 15px;
    border: 1px solid black;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 24px;
    min-width: 55px !important;

}

.small-input {
    max-width: 50% !important;
    min-width: 50% !important;
}

.add-account-modal {
    width: 500px !important;

    &>.ant-modal-content {
        background-color: #F0FCFA;
        border-radius: 0;
    }

    .ant-modal-title {
        font-weight: bold;
        font-size: 30px;
        background-color: #F0FCFA;
    }

    .ant-modal-close-x {
        width: 24px;
        height: 24px;
        border: 2px solid black;
        border-radius: 50%;

        svg {
            color: black;
        }
    }

    .buttons-wrapper {
        gap: 32px;
        margin-top: 37px;
        display: flex;
        flex-direction: row-reverse;
    }

    .modal-header {
        display: flex;
        text-align: center;
        flex-direction: column;
        gap: 28px;

        h1 {
            color: #000;
            font-size: 60px;
            font-weight: 400;
        }

        p {
            margin-bottom: 72px;
            color: #000;
            font-size: 30px;
            font-weight: 500;
        }
    }

    .modal-content {
        display: flex;
        justify-content: space-between;
    }

    .description {
        font-size: 10px;
    }

    .form-container {
        margin-top: 8px;

        .custom-input {
            width: 100%;
            max-width: unset;
        }

        .input-wrapper {
            width: 100%;
        }

        .element-wrapper {
            display: flex;
            width: 100%;
            gap: 2rem;

            .wrapper-select {
                margin-top: 0rem;
            }
        }
    }



    .validate {
        margin-top: 10px;
        text-align: center;

        .validate-button {
            align-items: center;
            width: 75%;
            background-color: #F0FCFA;
            color: black;
            font-weight: bold;
            border: 1px solid black;

            &:hover {
                background-color: black;
                color: white;
            }
        }
    }

    .wrapper-select {
        display: flex;
        align-items: start;
        flex-direction: column;
        margin-bottom: 1rem;
        margin-top: 1rem;
        gap: 0.5rem;
        width: 100%;

        .ant-select-selector {
            padding: 0 15px !important;
        }

        .input-wrapper {
            display: flex;
            gap: 2rem;
            align-items: center;
            justify-content: center;
        }

        .input-text {
            font-size: 1.6rem;
            font-weight: bold;
            margin-bottom: 5px;
            margin-top: 5px;
        }

        .img-container {
            margin-right: 10px;

        }

        .option-text {
            margin-left: 10px;
        }

        .option {
            display: flex;
            flex-direction: column;
            justify-content: space-around;

        }
    }
}