.ant-modal-content {
  background-color: #f0fcfa !important;
  padding: 10px 24px !important;
}

.error-displayer-form-modal {
  font-size: 1.3rem;
  font-weight: normal;
  color: red;
  width: 90%;
  margin: 0rem auto 0 auto;
  text-align: center;
}

.verify-inputs-popup {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10rem;
}

.button-verify-inputs {
  width: 245px;
  font-size: 20px;
  font-weight: 700;
  height: 41px;
}

.add-writing-modal {
  width: 575px !important;

  &>.ant-modal-content {
    background-color: $aliceBlue;
    border-radius: 0;
  }

  .ant-modal-title {
    font-weight: bold;
    font-size: 30px;
    background-color: $aliceBlue;
  }

  .ant-modal-close-x {
    width: 24px;
    height: 24px;
    border: 2px solid black;
    border-radius: 50%;

    svg {
      color: black;
    }
  }

  .buttons-wrapper {
    gap: 32px;
    margin-top: 37px;
    display: flex;
    flex-direction: row-reverse;
  }

  .modal-header {
    display: flex;
    text-align: center;
    flex-direction: column;
    gap: 28px;

    h1 {
      color: #000;
      font-size: 60px;
      font-weight: 400;
    }

    p {
      margin-bottom: 72px;
      color: #000;
      font-size: 30px;
      font-weight: 500;
    }
  }

  .modal-content {
    display: flex;
    justify-content: space-between;
  }

  .description {
    font-size: 10px;
  }

  .form-container {
    margin-top: 8px;

    .custom-input {
      width: 100%;
      max-width: unset;
    }

    .input-wrapper {
      width: 100%;
    }

    .element-wrapper {
      display: flex;
      width: 100%;
      gap: 2rem;

      .wrapper-select {
        margin-top: 0rem;
      }
    }
  }



  .validate {
    margin-top: 10px;
    text-align: center;

    .validate-button {
      align-items: center;
      width: 75%;
      background-color: $aliceBlue;
      color: black;
      font-weight: bold;
      border: 1px solid black;

      &:hover {
        background-color: black;
        color: white;
      }
    }
  }

  .wrapper-select {
    display: flex;
    align-items: start;
    flex-direction: column;
    margin-bottom: 1rem;
    margin-top: 1rem;
    gap: 0.5rem;
    width: 100%;

    .ant-select-selector {
      padding: 0 15px !important;
    }

    .input-wrapper {
      display: flex;
      gap: 2rem;
      align-items: center;
      justify-content: center;
    }

    .input-text {
      font-size: 1.6rem;
      font-weight: bold;
      margin-bottom: 5px;
      margin-top: 5px;
    }

    .img-container {
      margin-right: 10px;

    }

    .option-text {
      margin-left: 10px;
    }

    .option {
      display: flex;
      flex-direction: column;
      justify-content: space-around;

    }
  }
}



.element-status {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 4px;
  height: 22px;
  margin: 1px;
  border-radius: 40px;
  filter: drop-shadow(0px 3px 3px rgba(0, 0, 0, 0.16));
  padding: 2px 10px 2px 22px;
  font-weight: 600;
  width: 140px;

}

.ant-picker-footer {
  position: absolute;
  top: 0;
  width: fit-content !important;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: fit-content !important;
  position: absolute;
  right: 50%;
  top: 25px;

}

.ant-picker-footer-extra {
  position: absolute;
  top: 0;
  width: 50px !important;
  height: fit-content;
  min-width: none;

}

.custom-datepicker-dropdown {
  width: 375.4px;

  .ant-picker-header {
    width: 215.8px !important;
    height: 30.2px !important;
    border-radius: 53px !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    filter: drop-shadow(0px 3px 3px rgba(0, 0, 0, 0.16));
    background: #f7fef0;
    position: absolute;
    top: -16px !important;
    right: 79px !important;

    >button {
      color: black !important;
    }
  }

  .ant-picker-cell .ant-picker-cell-inner {
    font-weight: bold !important;
  }

  .ant-picker-date-panel {
    width: unset !important;
  }

  .ant-picker-panel-layout {
    padding: 9px 44px !important;
  }

  .ant-picker-cell-today .ant-picker-cell-inner::before {
    border-radius: 50% !important;
    border: 1px solid black !important;
  }

  .ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner {
    background: black !important;
    border-radius: 50% !important;
  }
}

.repeated-field-wrapper {
  display: flex;
  align-items: flex-end;
  gap: 2rem;

  .repeated-desc {
    white-space: nowrap;
    margin-right: 7rem;
    margin-bottom: 2rem;
  }
}

.modify-delete-actions {
  button {
    span {
      color: black;
    }
  }
}

.agree-action-button {
  background: transparent;
  color: black;
  border: 2px solid black;
}

.popup-desc-content {
  margin: 3rem 0;
}

.button-actions {
  button {
    span {
      color: black;
    }
  }
}

.submit-button-wrapper {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: center;
  gap: 7rem;
  margin-top: 1rem;

  .submit-button {
    align-items: center;
    background-color: $aliceBlue;
    color: black;
    font-weight: bold;
    border: 1px solid black;

    &:hover {
      background-color: black;
      color: white;
    }
  }
}

.form-compte {
  display: flex;
  flex-direction: column;
  gap: 20px;

  .custom-input {
    .error-displayer {
      text-align: left;
    }
  }

  .wrapper-select {
    .error-displayer-form-modal {
      text-align: left;
    }
  }
}