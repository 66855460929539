.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 3.2rem 15.1rem;
    display: flex;
    z-index: 1001;
    background-color: rgba(0, 0, 0, 0.5);

    @media (max-width: 1280px) {
        padding: 3.2rem 6.1rem;
    }

    &.hidden {
        display: none;
    }
}