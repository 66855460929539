.menu-option {
    span {
        display: flex;
        align-items: 'center';
        justify-content: space-between;
        gap: 1.8rem;

        &.compte-displayer-filter {
            padding: 1px 2px;
            border-radius: 2px;
            width: fit-content;
            height: 20px;
            font-size: 9px;
            display: flex;
            white-space: nowrap;
            align-items: center;
            justify-content: center;
            width: 50px;
            text-align: center;
        }

        .code {
            font-weight: 400;
        }

        .icon-menu-option {
            display: flex;
            height: 18px;

            &.disabled {
                opacity: 0.4;

                img {
                    filter: grayscale(0.8) brightness(0.8);
                    cursor: not-allowed;
                }

            }
        }
    }
}

.compte-displayer-filter {
    padding: 1px 2px;
    border-radius: 4px;
    width: fit-content;
    height: 20px;
    font-size: 11px;
    display: flex;
    white-space: nowrap;
    align-items: center;
    justify-content: center;
    text-align: center;
    width: 40px;
}

.add-account-select {
    display: flex;
    justify-content: center;
    align-items: "center";
    gap: 10px;
    font-weight: 600;

    .add-alert {
        font-weight: 400;
        font-size: 11px;
        display: flex;
        align-items: center;
    }
}